import React from "react"
import { graphql } from "gatsby"
import { Box, Grid, Heading } from "theme-ui"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components

import Layout from "../../components/Layout"
import Textarea from "../../components/Textarea"

const HistoryPageTemplate = (props) => {
  const {
    data: {
      wpPage: {
        seo,
        template: {
          acf: { section1 },
        },
      },
    },
  } = props

  return (
    <Layout {...props} seo={seo}>
      {/*
      Section 1 -----------------------------------------------------------------------------------------------
      */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
          mb: ["10px", 0],
        }}
      >
        <Grid
          sx={{
            position: "relative",
            height: "100%",
            gridRow: 1,
            gap: "0 0",
            bg: "white",
            width: "100%",
          }}
          columns={[1, "50% 50%", "50% 50%"]}
        >
          <Box
            sx={{
              position: "relative",
              bg: "black",
              gridRowStart: 1,
              gridColumnStart: 1,
              width: "100%",
              display: "flex",
              height: ["300px", "400px", "450px"],
            }}
          >
            {section1?.headline && (
              <Heading
                variant="text.sectionHeading"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  ml: [4, 5, 6],
                  mb: 5,
                }}
              >
                {Parser(section1?.headline)}
              </Heading>
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              gridRowStart: [2, 1],
              gridColumnStart: [1, 2],
              height: ["300px", "400px", "450px"],
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image1 && (
              <GatsbyImage
                image={section1?.image1}
                alt={section1?.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>

          <Box
            sx={{
              position: "relative",
              bg: "white",
              gridRowStart: [4, 2],
              gridRowEnd: [5, 4],
              gridColumnStart: [1, 2],
              width: "100%",
              height: "100%",
              minHeight: "300px",
              pt: ["30px", "90px"],
              pl: ["30px", "45px"],
              pr: ["30px", "105px"],
              pb: "30px",
            }}
          >
            {section1?.text && <Textarea content={section1?.text} />}
          </Box>

          <Box
            sx={{
              position: "relative",
              p: ["5px 0", 0],
              gridRowStart: [3, 2],
              gridColumnStart: 1,
              width: "100%",
              height: ["300px", "100%", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%", "450px"],
              },
            }}
          >
            {section1?.image2 && (
              <GatsbyImage
                image={section1?.image2}
                alt={section1?.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              py: "1%",
              gridRowStart: [6, 3],
              gridColumnStart: 1,
              width: "100%",
              height: ["300px", "100%", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%", "450px"],
              },
            }}
          >
            {section1?.image3 && (
              <GatsbyImage
                image={section1?.image3}
                alt={section1?.image3?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query HistoryPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpTemplate_History {
          templateName
          acf {
            fieldGroupName
            section1 {
              fieldGroupName
              headline
              text
              image1 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              image3 {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HistoryPageTemplate
